import InstallmentMessage from '@/components/Installments/InstallmentMessage/InstallmentMessage'
import s from '@/components/Installments/Installments.module.css'
import { PaymentPlan } from '@/types'
import { centsToEuros } from '@/utils/prices'
import cx from 'classnames'
import { fromUnixTime } from 'date-fns'
import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'

type Props = {
  installment: PaymentPlan
  offerFees: boolean
  merchantName?: string
  isFirstInstallment?: boolean
  hasDeferredCapture?: boolean
  index?: number
}

export const Installment: React.FC<Props> = ({
  installment: { total_amount, purchase_amount, due_date },
  offerFees,
  isFirstInstallment = false,
  merchantName = '',
  index = 0,
  hasDeferredCapture = false,
}: Props) => (
  <div className={cx(s.installmentContent)}>
    <div
      className={cx(s.installment, { [s.firstInstallment]: isFirstInstallment })}
      data-testid="installment"
    >
      <InstallmentMessage
        date={fromUnixTime(due_date)}
        hasDeferredCapture={hasDeferredCapture}
        index={index}
      />
      <div>
        <FormattedNumber
          value={centsToEuros(offerFees ? purchase_amount : total_amount)}
          style="currency"
          currency="EUR"
        />
      </div>
    </div>
    {hasDeferredCapture && merchantName && (
      <span className={s.deferredNotice}>
        <FormattedMessage
          id="installments.deferred_capture_installment_plan_message"
          defaultMessage="Your payments will begin automatically once {merchantName} has validated your order"
          values={{ merchantName }}
          description="This is the description of the first installment captured in the deferred capture payment plan"
        />
      </span>
    )}
  </div>
)
