import { isToday } from 'date-fns'
import React, { FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

type Props = {
  date: Date
  hasDeferredCapture?: boolean
  index?: number
}

const InstallmentMessage: FC<Props> = ({ date, hasDeferredCapture = false, index = 0 }) => {
  if (hasDeferredCapture) {
    return isToday(date) ? (
      <FormattedMessage
        id="installments.deferred_capture_installment_plan"
        defaultMessage="At the time of order processing"
        description="This is the second line of the installment plan displayed in the summary in the payment form for deferred capture payments"
      />
    ) : (
      <FormattedMessage
        id="installments.deferred_capture_installment_plan_months"
        defaultMessage="{index, plural, one {# month} other {# months}} after"
        values={{ index }}
        description="This is the second line of the installment plan displayed in the summary in the payment form for deferred capture payments"
      />
    )
  }

  return isToday(date) ? (
    <FormattedMessage
      id="installments.today"
      defaultMessage="Today"
      description="This is the first line of the installment plan displayed in the summary in the payment form, showing what the end customer will have to pay today"
    />
  ) : (
    <FormattedDate value={date} day="numeric" month="long" year="numeric" />
  )
}

export default InstallmentMessage
