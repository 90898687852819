export interface CustomDataTemplate {
  desc: string | null
  key: string
  label: string
  regex: string | '' | null
  required: boolean
}

export interface Terminal {
  created?: number
  updated?: number
  id?: string
  name: string
  provider: string
  provider_reference: string
}

export const rolesSortedByPermissionLevel = [
  'disabled' as const,
  'salesperson_finalize_only' as const,
  'salesperson' as const,
  'accountant' as const,
  'operator' as const,
  'manager' as const,
  'admin' as const,
  'owner' as const,
]

type MerchantUserRole = (typeof rolesSortedByPermissionLevel)[0]

export enum ActiveTags {
  selfServedCheckout = 'feature:pos:self_served_checkout',
  iobsp = 'feature:pos:iobsp',
  mdm = 'feature:pos:mdm',
  ept = 'feature:pos:ept',
  fastMode = 'feature:pos:fast_mode',
  cantExitFinalize = 'feature:pos:cant_exit_finalize',
  deferredCapture = 'feature:pos:deferred_capture',
}

export interface Merchant {
  country: string
  // FIXME: This flag will be soon properly implemented in the API
  can_customer_info_be_optional: boolean
  can_pay_in: boolean
  can_pay_out: boolean
  custom_data_template: CustomDataTemplate[]
  customer_choose_installments: boolean
  default_installments_count: number
  has_pos_pay_by_link: boolean
  has_pos_pay_by_sms: boolean
  has_pos_pay_on_device: boolean
  has_deferred_capture: boolean
  id: string
  logo_url: string | null
  maximum_purchase_amount: number
  maximum_purchase_exposure: number
  minimum_purchase_amount: number
  name: string
  order_reference: {
    required: boolean
    tip: string | null
  }
  pos_active_tags: ActiveTags[]
  pos_enable_hotjar: boolean
  pos_zendesk_chat_widget_key: string | null
  use_new_pos_ui: boolean
  use_short_id_in_merchant_ref_and_show_on_pos: boolean
}

export interface Me {
  can_disable_customer_fees: boolean
  email: string
  id: string
  locale: string
  request_country: string | null
  /**
   * - a [Alma Role](https://support.getalma.eu/hc/fr/articles/360009191280) when the user has a role for this merchant
   * - `null` if the user has a role at a merchant's child.
   *   _(This is a limitation on the backend, if it becomes a problem a backend fix will be needed)_
   */
  role_for_merchant: MerchantUserRole | null
  merchant: Merchant
  merchants: Array<{
    is_parent: false
    name: string
    value: string
    user_role: MerchantUserRole
  }>
  name: string
  real_user: null | { email: string; id: string; name: string }
  terminals: Terminal[]
  use_terminal: boolean
}

export const isAtLeastRole: (
  me: Pick<Me, 'role_for_merchant'>,
  role: MerchantUserRole,
  option?: { exclusive?: boolean }
) => boolean = (me, role, { exclusive = false } = {}) => {
  const indexRoleToCompare = rolesSortedByPermissionLevel.indexOf(role)
  const indexRoleMe = rolesSortedByPermissionLevel.indexOf(me.role_for_merchant ?? 'salesperson')
  return exclusive ? indexRoleMe > indexRoleToCompare : indexRoleMe >= indexRoleToCompare
}
export const hasFeatureTag: (tag: ActiveTags | ActiveTags[], me: Me) => boolean = (tag, me) => {
  if (!Array.isArray(tag)) {
    return me.merchant.pos_active_tags.includes(tag)
  }

  return tag
    .map((singleTag) => me.merchant.pos_active_tags.includes(singleTag))
    .every((match) => match)
}
