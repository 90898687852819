import { Me, PaymentPlan } from '@/types'
import { getUnixTime } from 'date-fns'
import React from 'react'
import { Installment } from '@/components/Installments/Installment'
import { useFormContext } from 'react-hook-form'
import { PaymentCreateFormValues } from '@/components/Forms/PaymentCreate/types'

type Props = {
  me: Me
  paymentPlan: PaymentPlan[]
}

export const DeferredCaptureInstallments: React.FC<Props> = ({ me, paymentPlan }: Props) => {
  const { watch } = useFormContext<PaymentCreateFormValues>()
  const offerFees = Boolean(me.can_disable_customer_fees && watch('offerFees.offered'))

  return (
    <>
      {/* Fake installment line with an amount set to 0€ */}
      <Installment
        installment={{
          total_amount: 0,
          purchase_amount: 0,
          due_date: getUnixTime(new Date()),
          customer_fee: 0,
          customer_interest: 0,
        }}
        isFirstInstallment
        offerFees={false}
      />
      {/* First installment with a deferred notice */}
      <Installment
        installment={paymentPlan[0]}
        hasDeferredCapture
        offerFees={offerFees}
        merchantName={me.merchant.name}
      />
      {/* Rest of payment plan, due dates being approximate */}
      {paymentPlan.slice(1).map((installment, index) => (
        <Installment
          key={`${installment.due_date}-${installment.customer_fee}-${installment.purchase_amount}`}
          installment={installment}
          index={index + 1}
          hasDeferredCapture
          offerFees={offerFees}
        />
      ))}
    </>
  )
}
